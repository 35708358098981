import * as React from "react"
import { ImgDestra, ImgSinistra, ImgCentro, ImgLogo, Img, Citazione, IndiceLuoghi, PaginaTesto, ChiSono, ChiSiamo, A, FbPage } from "../../../components/componentiPagine"

export default function Render() {
	return <PaginaTesto lang="it" name="londra1843">
<h1>Antonio, lo scrittore mazziniano</h1>
<p>Già durante il primo soggiorno a Londra, Antonio si era riavvicinato a Giuseppe Mazzini e agli ideali mazziniani, ma è partire dal 1843, in seguito al suo ritorno nella capitale britannica, che inizia a prendere attivamente parte ai lavori della Scuola popolare per bambini italiani fondata dal patriota genovese nel quartiere di Clerkenwell.</p>
<ImgLogo nome="bimbimazzini.png" alt="bambini" didascalia="Mazzini e gli scolari della Scuola popolare italiana di Londra (Hatton Garden)"/>
<ImgLogo nome="orfani.png" alt="orfani" didascalia="Gli orfani italiani a Londra, sfruttati come musicisti di strada"/>
<p>Fino al 1848 il rapporto è molto stretto e cordiale, e in questi anni Antonio si impegna per difendere l’immagine di Mazzini presso il pubblico inglese e a promuovere, attraverso l’arma della finzione letteraria, una conoscenza migliore delle vicende italiane.</p>
<p>Risalgono infatti a questo periodo la raccolta di poesie &quot;Oltremonte e Oltremare&quot; (1844), i racconti di &quot;Blackgown Papers&quot; (1846) e quelli di &quot;Scenes from Italian Life&quot; (1850).</p>
<ImgCentro nome="originali.png" alt="Copertine" didascalia="Copertine della prima edizione di 'Blackgown Papers' (1846) e 'Italy, Past and Present' (1841) "/>
<p>Inoltre, per quanto concerne la saggistica, in seguito alla ristampa di Italy, General View… con il titolo Italy, Past and Present avvenuta nel 1841, esce il nuovo &quot;Italy, Past and Present&quot; (1848), il quale raccoglie tutti gli articoli pubblicati sul Monthly Magazine di Londra a partire dal 1843.</p>
<ImgCentro href="https://emiliaromagnaturismo.it/it/localita/bardi" nome="sidolo.png" alt="Sidolo" didascalia="Sidolo, Comune di Bardi (PR) - Luogo di nascita di Morello, protagonista di uno dei racconti di 'Blackgown Papers'"/>
<p>Nel frattempo, nel 1847, Antonio sposa a Londra Juliet Schunk, facoltosa ereditiera di origine tedesca e alla quale è stato presentato dall’amico pittore Spiridione Gambardella.</p>
<p>Grazie all’unione con Juliet, di 16 anni più giovane di lui, Antonio migliora la propria condizione finanziaria e finisce per lui il lungo periodo di sacrifici economici.</p>
<h2>Link per approfondire</h2>
<p><a href="https://it.wikipedia.org/wiki/Clerkenwell">“Clerkenwell (Londra)”</a></p>
<p><a href=" https://londra.italiani.it/giuseppe-mazzini-fondatore-di-una-scuola-italiana-a-londra/">“Scuola mazziniana di Londra”</a></p>
<p><a href="https://www.britishmuseum.org/collection/term/BIOG162676">“Spiridione Gambardella”</a></p>
<p><a href="https://www.antoniogallenga.it/it/p/i_blackgown_papers">“The Blackgown Papers”</a></p>

	</PaginaTesto>
}